import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { omit } from 'lodash';
import { CardActions, CardContent, CardHeader, Grid, Divider } from '@material-ui/core';
import { useNotify, required, email, Edit } from 'react-admin';
import { useAuth } from '@/utils/auth';
import { USERS } from '@/vars/resources';
import { Button, Page } from '@/components';
import { TextInput } from '@/components/input';
import PaymentMethodForm from './PaymentMethodForm';
import PasswordChecklist from 'react-password-checklist';

const ProfileForm = ({ record, saving, redirect, save }) => {
  const notify = useNotify();
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handlePasswordAgainChange = e => {
    const newPasswordAgain = e.target.value;
    setPasswordAgain(newPasswordAgain);
  };

  const passwordValidation = () => {
    if (!isPasswordValid) {
      return 'Please create a password that fulfills the below criteria.';
    } else {
      return undefined;
    }
  };

  const passwordAgainValidation = () => {
    if (password !== passwordAgain) return 'Confirm password must match with the password.';
    else return undefined;
  };

  const validatePassword = [required('Please enter a secure password.'), passwordValidation];
  const validatePasswordAgain = [required('Please re-enter the password.'), passwordAgainValidation];

  const onSubmit = (data, form, callback) => {
    const cleanData = omit(data, ['createdAt', 'updatedAt', 'password0']);
    save(cleanData, redirect, {
      onSuccess: () => {
        notify('ra.notification.updated', 'info', { smart_count: 1 }, false);
        callback();
      },
      onFailure: error => {
        const { details, message } = error;
        if (details) {
          const { code } = details;
          switch (code) {
            case 'auth/invalid-email':
            case 'auth/email-already-exists':
              return callback({ email: message });
            case 'auth/invalid-password':
              return callback({ password: message });
            default:
          }
        }
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
        callback();
      },
    });
  };

  const sanitizeRecord = omit(record, ['password', 'password0']);
  return (
    <Form onSubmit={onSubmit} initialValues={sanitizeRecord} validateOnBlur>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <CardHeader title="Profile details" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextInput label="First name" name="firstName" validate={required('Please enter a first name.')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Last name" name="lastName" validate={required('Please enter a last name.')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="Email Address"
                    name="email"
                    type="email"
                    validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
                    disabled
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardHeader title="Change password" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="Password"
                    name="password0"
                    type="password"
                    onChange={e => handlePasswordChange(e)}
                    validate={validatePassword}
                  />               
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="Confirm password"
                    name="password"
                    onChange={e => handlePasswordAgainChange(e)}
                    type="password"
                    validate={validatePasswordAgain}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PasswordChecklist
                    rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                    minLength={8}
                    value={password}
                    valueAgain={passwordAgain}
                    onChange={isValid => {
                      setIsPasswordValid(isValid);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>

            {record.isOwner && (
              <>
                <CardHeader title="Company details" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="Company name"
                        name="client.companyName"
                        validate={required('Please enter a company name.')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="Company registration no."
                        name="client.companyRegNumber"
                        validate={required('Please enter a company registration no.')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput label="Company address" name="client.companyAddress" />
                    </Grid>
                  </Grid>
                </CardContent>
                <PaymentMethodForm mode="read" record={record} />
              </>
            )}

            <Divider />
            <CardActions>
              <Button type="submit" variant="contained" color="primary" loading={saving}>
                Save
              </Button>
            </CardActions>
          </form>
        );
      }}
    </Form>
  );
};

const Profile = props => {
  const { permissions } = useAuth();
  const id = permissions.user_id;

  return (
    <Page sectionTitle="settings" title="Profile">
      <Edit id={id} resource={USERS} basePath={props.match.url} undoable={false}>
        <ProfileForm />
      </Edit>
    </Page>
  );
};

export default Profile;
