import React from 'react';
import { CardContent, CardHeader, Grid } from '@material-ui/core';
import { required, email, BooleanInput } from 'react-admin';
import { TextInput } from '@/components/input';

const PaymentMethodForm = ({ record, mode, ...rest }) => {
  return (
    <>
      <CardHeader title="Payment settings" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Merchant code"
              source="client.paymentMethods.ipay88.merchantCode"
              validate={required('Please enter a merchant code.')}
              disabled={mode !== 'edit'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Merchant key"
              source="client.paymentMethods.ipay88.merchantKey"
              validate={required('Please enter a merchant key.')}
              disabled={mode !== 'edit'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Email"
              source="client.paymentMethods.ipay88.email"
              validate={[required('Please enter a valid email.'), email('Please enter a valid email.')]}
              disabled={mode !== 'edit'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Phone number"
              source="client.paymentMethods.ipay88.phoneNumber"
              validate={required('Please enter a phone number.')}
              disabled={mode !== 'edit'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Username"
              source="client.paymentMethods.ipay88.username"
              validate={required('Please enter a username.')}
              disabled={mode !== 'edit'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {mode === 'edit' ? <BooleanInput label="Active" source="client.paymentMethods.ipay88.isActive" /> : null}
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default PaymentMethodForm;
